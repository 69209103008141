import { useState, useEffect } from "react";
import { set, useForm } from "react-hook-form";
import { useHistory, Redirect, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { usePost } from "../auth/usePost";
import { admin } from "../../redux/actions/usersAction";
import URL from "../../constant/url";
import Helmet from "react-helmet";

const Login = () => {
  let history = useHistory();
  let dispatch = useDispatch();
  const userSignin = useSelector((state) => state.userSignIn);
  const { userInfo, success, error } = userSignin;
  const { register, handleSubmit } = useForm();
  const [requestBody, setRequestBody] = useState({});

  const onSubmit = (body) => {
    dispatch(admin(body));
  };
  useEffect(() => {
    // if (userInfo != undefined && userInfo.id > 0) {
    //   history.push("/admindashboard");
    // }
    if (
      userInfo != undefined &&
      userInfo.id > 0 &&
      userInfo.isSupperAdmin == true
    ) {
      history.push("/admindashboard");
    }
    if (
      userInfo != undefined &&
      userInfo.id > 0 &&
      userInfo.isSupperAdmin == false
    ) {
      let managerID = {
        address: userInfo?.address,
        email: userInfo?.email,
        id: userInfo?.branchID,
        name: userInfo?.name,
      };

      // return <Link
      //   to={{
      //     pathname: `/manager/${userInfo.branchID}`,
      //     state: { manager: managerID },
      //   }}
      // />;
      history.replace(`/manager/${userInfo.branchID}`, { manager: managerID });
      window.location.reload();
    }
  }, [dispatch, history, Redirect, userInfo]);

  return (
    <div id="app">
      <Helmet>
        <link
          rel="stylesheet"
          href="/assets/modules/bootstrap/css/bootstrap.min.css"
        />
        <link
          rel="stylesheet"
          href="/assets/modules/fontawesome/css/all.min.css"
        />

        <link
          rel="stylesheet"
          href="/assets/modules/jqvmap/dist/jqvmap.min.css"
        />
        <link
          rel="stylesheet"
          href="/assets/modules/summernote/summernote-bs4.css"
        />
        <link
          rel="stylesheet"
          href="/assets/modules/owlcarousel2/dist/assets/owl.carousel.min.css"
        />
        <link
          rel="stylesheet"
          href="/assets/modules/owlcarousel2/dist/assets/owl.theme.default.min.css"
        />

        <link rel="stylesheet" href="/assets/css/style.min.css" />
        <link rel="stylesheet" href="/assets/css/components.min.css"></link>
      </Helmet>
      <section class="section">
        <div class="container mt-5">
          <div class="row">
            <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
              <div class="login-brand">
                {/* <img src="http://puffintheme.com/craft/codiepie/dist/assets/img/CodiePie-fill.svg" alt="logo" width="100" class="shadow-light rounded-circle" /> */}
              </div>
              <div class="card card-primary">
                <div class="card-header">
                  <h4>Login</h4>
                  <br />
                </div>
                <div class="card-body">
                  <h4>
                    {error && <h4 style={{ color: "red" }}> {error} </h4>}{" "}
                  </h4>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    class="needs-validation"
                    novalidate=""
                  >
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input
                        id="email"
                        type="email"
                        {...register("email")}
                        class="form-control"
                        name="email"
                        tabindex="1"
                        required
                        autofocus
                      />
                      <div class="invalid-feedback">
                        Please fill in your email
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="d-block">
                        <label for="password" class="control-label">
                          Password
                        </label>
                        {/* <div class="float-right">
                                            <a href="auth-forgot-password.html" class="text-small">
                                            Forgot Password?
                                            </a>
                                        </div> */}
                      </div>
                      <input
                        id="password"
                        type="password"
                        {...register("password")}
                        class="form-control"
                        name="password"
                        tabindex="2"
                        required
                      />
                      <div class="invalid-feedback">
                        please fill in your password
                      </div>
                    </div>
                    {/* <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" name="remember" class="custom-control-input" tabindex="3" id="remember-me" />
                                        <label class="custom-control-label" for="remember-me">Remember Me</label>
                                    </div>
                                </div> */}
                    <div class="form-group">
                      <button
                        type="submit"
                        class="btn btn-primary btn-lg btn-block"
                        tabindex="4"
                      >
                        Login
                      </button>
                    </div>
                  </form>
                  {/* <div class="text-center mt-4 mb-3">
                                <div class="text-job text-muted">Login With Social</div>
                            </div>
                            <div class="row sm-gutters">
                                <div class="col-6">
                                    <a class="btn btn-block btn-social btn-facebook"><span class="fab fa-facebook"></span> Facebook</a>
                                </div>
                                <div class="col-6">
                                    <a class="btn btn-block btn-social btn-twitter"><span class="fab fa-twitter"></span> Twitter</a>
                                </div>
                            </div> */}
                </div>
              </div>
              {/* <div class="mt-5 text-muted text-center">
                        Don't have an account? <a href="auth-register.html">Create One</a>
                    </div> */}
              <div class="simple-footer">
                Copyright &copy; GREEN CAR WASH {new Date().getFullYear()}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
