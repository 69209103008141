export const USER_REGISTER_REQUEST: string = "USER_REGISTER_REQUEST"
export const USER_REGISTER_SUCCESS: string = "USER_REGISTER_SUCCESS"
export const USER_REGISTER_FAIL: string = "USER_REGISTER_FAIL"

export const USER_SIGNIN_REQUEST: string = "USER_SIGNIN_REQUEST"
export const USER_SIGNIN_SUCCESS: string = "USER_SIGNIN_SUCCESS"
export const USER_SIGNIN_FAIL: string = "USER_SIGNIN_FAIL"
export const USER_SIGNOUT: string = "USER_SIGNOUT"

export const STUDENT_SIGNIN_REQUEST: string = "STUDENT_SIGNIN_REQUEST"
export const STUDENT_SIGNIN_SUCCESS: string = "STUDENT_SIGNIN_SUCCESS"
export const STUDENT_SIGNIN_FAIL: string = "STUDENT_SIGNIN_FAIL"
export const STUDENT_SIGNOUT: string = "STUDENT_SIGNOUT"

export const USER_CHANGE_EMAIL_REQUEST:string = "USER_CHANGE_EMAIL_REQUEST"
export const USER_CHANGE_EMAIL_SUCCESS:string = "USER_CHANGE_EMAIL_SUCCESS"
export const USER_CHANGE_EMAIL_FAIL:string = "USER_CHANGE_EMAIL_FAIL"

export const USER_CHANGE_PASSWORD_REQUEST:string = "USER_CHANGE_PASSWORD_REQUEST"
export const USER_CHANGE_PASSWORD_SUCCESS:string = "USER_CHANGE_PASSWORD_SUCCESS"
export const USER_CHANGE_PASSWORD_FAIL:string = "USER_CHANGE_PASSWORD_FAIL"

export const USER_VALIDATE_OTP_REQUEST:string = "USER_VALIDATE_OTP_REQUEST"
export const USER_VALIDATE_OTP_SUCCESS:string = "USER_VALIDATE_OTP_SUCCESS"
export const USER_VALIDATE_OTP_FAIL:string = "USER_VALIDATE_OTP_FAIL"

export const USER_VALIDATE_PASS_OTP_REQUEST:string = "USER_VALIDATE_PASS_OTP_REQUEST"
export const USER_VALIDATE_PASS_OTP_SUCCESS:string = "USER_VALIDATE_PASS_OTP_SUCCESS"
export const USER_VALIDATE_PASS_OTP_FAIL:string = "USER_VALIDATE_PASS_OTP_FAIL"

export const USER_CHANGE_DETAILS_REQUEST:string = "USER_CHANGE_DETAILS_REQUEST"
export const USER_CHANGE_DETAILS_SUCCESS:string = "USER_CHANGE_DETAILS_SUCCESS"
export const USER_CHANGE_DETAILS_FAIL:string = "USER_CHANGE_DETAILS_FAIL"

export const SECTION_DATA_REQUEST: string = "SECTION_DATA_REQUEST"
export const SECTION_DATA_SUCCESS: string = "SECTION_DATA_SUCCESS"
export const SECTION_DATA_FAIL: string = "SECTION_DATA_FAIL"

export const ADD_STUDENT_REQUEST: string = "ADD_STUDENT_REQUEST";
export const ADD_STUDENT_SUCCESS: string = "ADD_STUDENT_SUCCESS";
export const ADD_STUDENT_FAIL: string = "ADD_STUDENT_FAIL";

export const ADD_COURSE_REQUEST: string = "ADD_COURSE_REQUEST";
export const ADD_COURSE_SUCCESS: string = "ADD_COURSE_SUCCESS";
export const ADD_COURSE_FAIL: string = "ADD_COURSE_FAIL";

export const ADD_RESULT_REQUEST: string = "ADD_RESULT_REQUEST";
export const ADD_RESULT_SUCCESS: string = "ADD_RESULT_SUCCESS";
export const ADD_RESULT_FAIL: string = "ADD_RESULT_FAIL";
