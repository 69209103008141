export const GET_PRODUCT_REQUEST: string = "GET_PRODUCT_REQUEST"
export const GET_PRODUCT_SUCCESS: string = "GET_PRODUCT_SUCCESS"
export const GET_PRODUCT_FAIL: string = "GET_PRODUCT_FAIL"


export const GET_PRODUCT_DETAILS_REQUEST: string = "GET_PRODUCT_DETAILS_REQUEST"
export const GET_PRODUCT_DETAILS_SUCCESS: string = "GET_PRODUCT_DETAILS_SUCCESS"
export const GET_PRODUCT_DETAILS_FAIL: string = "GET_PRODUCT_DETAILS_FAIL"

export const GET_PRODUCT_DETAILS_RESET: string = "GET_PRODUCT_DETAILS_RESET"

